import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MainWrapper = styled.div`
  min-height: 100vh;

  @media (min-width: 768px) {
    display: flex;
    jusdtify-content: center;
    align-items: center;
    padding-top: 50px;
  }
`

const MainTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;

  small {
    font-size: 28px;
    display: block;
  }

@media (min-width: 768px) {
  font-size: 60px;
  
  small {
    font-size: 35px;
  }

  @media (min-width: 1200px) {
    font-size: 80px;
    
    small {
      font-size: 45px;
    }
}
`

const ContentWrapper = styled.div`
  > div {
    padding: 20px 0;
  }
`

const Impressum = () => (
  <>
  <SEO title="Impressum" index="noindex, follow" description="Pilze im Christentum | Impressum" />
    <Header />
  <Layout>
    <MainWrapper>
      <ContentWrapper>
        <MainTitle>
          Impressum
        </MainTitle>
        <div>
        <p><strong>Kontakt-Adresse</strong></p>
<p>Sascha Zengerling<br />
Fronwaldstrasse 94<br />
8046 Zürich<br />
Schweiz</p>
<p>Telefon:
  <br />044 372 39 52</p>
<p></p>
<p><strong>Haftungsausschluss</strong></p>
<p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.</p>
<p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff
    oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder
    durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
<p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
    gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die
    Veröffentlichung zeitweise oder endgültig einzustellen.</p>
<p></p>
<p><strong>Haftungsausschluss für Links</strong></p>
<p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche
    Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene
    Gefahr des jeweiligen Nutzers.</p>
<p></p>
<p><strong>Urheberrechte</strong></p>
<p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website,
    gehören ausschliesslich <strong>Sascha Zengerling</strong> oder den speziell genannten Rechteinhabern. Für
    die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus
    einzuholen.</p>
<p></p><br />Quelle: <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a>
        </div>
      </ContentWrapper>
    </MainWrapper>
  </Layout>
  </>
)

export default Impressum
