import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'

const NavWrapper = styled.nav`
  background: #3e63a0;
  padding: 15px 0;

  > div {
    max-width: 800px;
    margin: 0 auto;
  }

  a {
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
  }

  a:hover, a.active {
    color: #bababa;
  }
`

const Header = () => (
  <header>
    <NavWrapper>
      <div>
      <Link to="/" activeClassName="active">Startseite</Link>
      <Link to="/impressum" activeClassName="active">Impressum</Link>
      </div>
    </NavWrapper>
  </header>
)


export default Header
